import { CheckIcon } from "@heroicons/react/20/solid"
import classNames from "classnames"
import React, { useEffect, useState } from "react"
import { ButtonSecondary } from "../../UI/Button/Button"
import { connector, ContainerProps } from "./containers/Pricing.containers"

import {
  findPlanByNameAndInterval,
  PaymentPlansEntity,
  PaymentPricesEntity,
} from "@foudroyer/interfaces"
import { useLocation } from "@reach/router"
import clsx from "clsx"
import getSymbolFromCurrency from "currency-symbol-map"
import { ITranslations } from "../../../interfaces/ITranslations"
import { FormattedMessage } from "../../general/FormattedMessage/FormattedMessage"
import { PaymentsMethods } from "./components/payment-method"
import { PricingSwitchBillingInterval } from "./components/PricingSwitchBillingInterval"

type Tier = {
  name: ITranslations["keys"]
  id: string
  plan: PaymentPlansEntity
  reduction?: number | null
  priceMonthly: number
  label: ITranslations["keys"]
  features: Array<ITranslations["keys"]>
  featuresComingSoon: Array<ITranslations["keys"]>
  highlight?: boolean
}

const paymentTiers: Array<Tier> = [
  {
    name: "marketing/pricing/newbie/label",
    id: "newbie",
    priceMonthly: 9.99,
    plan: PaymentPlansEntity.newbie,
    reduction: 0.5,
    label: "marketing/pricing/label",
    features: [
      "marketing/pricing/features/1-website",
      "marketing/pricing/features/200-per-site",
      "marketing/pricing/features/indexing-auto",
    ],
    featuresComingSoon: [],
    highlight: false,
  },
  {
    name: "marketing/pricing/premium/label",
    id: "tier-team",
    priceMonthly: 29.99,
    plan: PaymentPlansEntity.indexation,
    reduction: 0.5,
    label: "marketing/pricing/label",
    features: [
      "marketing/pricing/features/10-website",
      "marketing/pricing/features/400-per-site",
      "marketing/pricing/features/indexing-auto",
    ],
    featuresComingSoon: [],
    highlight: false,
  },
  {
    name: "marketing/pricing/teams/label",
    id: "tier-team",
    priceMonthly: 49.99,
    plan: PaymentPlansEntity["indexation/teams"],
    reduction: 0.5,
    label: "marketing/pricing/label",
    features: [
      "marketing/pricing/features/20-website",
      "marketing/pricing/features/800-per-site",
      "marketing/pricing/features/indexing-auto",
      "marketing/pricing/features/multiple-search-console",
    ],
    featuresComingSoon: [],
    highlight: true,
  },
  {
    name: "marketing/pricing/enterprise/label",
    id: "enterprise",
    plan: PaymentPlansEntity.enterprise,
    priceMonthly: 129.99,
    reduction: 0.5,
    label: "marketing/pricing/label",
    highlight: false,
    features: [
      "marketing/pricing/features/100-website",
      "marketing/pricing/features/2000-per-site",
      "marketing/pricing/features/indexing-auto",
      "marketing/pricing/features/multiple-search-console",
    ],
    featuresComingSoon: [],
  },
]

const freeTier: Tier = {
  name: "marketing/pricing/free/label",
  id: "tier-hobby",
  priceMonthly: 9.99,
  plan: PaymentPlansEntity["indexation/free"],
  label: "marketing/pricing/label",
  features: [
    "marketing/pricing/features/1-website",
    "marketing/pricing/features/just-10",
    "marketing/pricing/features/manual-indexing",
  ],
  featuresComingSoon: [],
}

type Props = {
  onTry: () => void
  onSubscribe: (
    plan: PaymentPlansEntity,
    interval: "monthly" | "yearly"
  ) => void
  onSupport: () => void
  onLoadAdjustedPricing: () => void
  className?: string
  source: "indexation/quota" | "indexation/report" | string | null
  type: PaymentPlansEntity
  products: PaymentPricesEntity
} & ContainerProps

const roundDecimal = (num) => {
  return Math.round(num * 100) / 100
}

export const Wrapper: React.FC<Props> = (props) => {
  useEffect(() => {
    props.onLoadAdjustedPricing()
  }, [])

  const [interval, setInterval] = useState<"monthly" | "yearly">("monthly")
  const tiers = paymentTiers
  const { href } = useLocation()
  const url = new URL(href || "https://www.foudroyer.com")
  const why = url.searchParams.get("why")

  return (
    <>
      <div className="isolate overflow-x-hidden">
        <div className="max-w-9xl mx-auto overflow-hidden px-6 pb-80 pt-24 text-center lg:px-8">
          <div className="mx-auto max-w-4xl">
            <span className=" text-base font-semibold text-pink-400">
              <FormattedMessage id={tiers[0].label} />
            </span>

            <h2 className="mt-2  text-4xl font-semibold tracking-tight sm:text-5xl">
              {(!why ||
                (why !== "indexation/quota" &&
                  why !== "indexation/report" &&
                  why !== "indexation/index-all" &&
                  why !== "indexation/trial-expired" &&
                  why !== "indexation/auto-index" &&
                  why !== "multi-google-search" &&
                  why !== "indexation/add-website")) && (
                <FormattedMessage id={"marketing/pricing/title"} />
              )}

              {why === "indexation/add-website" && (
                <FormattedMessage id="marketing/pricing/title/add-website" />
              )}

              {why === "indexation/auto-index" && (
                <FormattedMessage id="marketing/pricing/title/indexation/auto-index" />
              )}

              {why === "indexation/index-all" && (
                <FormattedMessage id="marketing/pricing/title/indexation/index-all" />
              )}

              {why === "indexation/quota" && (
                <FormattedMessage id="marketing/pricing/title/indexation/quota" />
              )}
              {why === "indexation/report" && (
                <FormattedMessage id="marketing/pricing/title/report/quota" />
              )}

              {why === "indexation/trial-expired" && (
                <FormattedMessage id="marketing/pricing/title/trial-expired" />
              )}

              {why === "multi-google-search" && (
                <FormattedMessage id="marketing/pricing/title/multi-google-search" />
              )}
            </h2>
          </div>

          <div className="relative mt-4">
            <p className="mx-auto max-w-3xl text-lg leading-8 text-slate-700">
              {(!why ||
                (why !== "indexation/quota" &&
                  why !== "multi-google-search" &&
                  why !== "indexation/index-all" &&
                  why !== "indexation/auto-index" &&
                  why !== "indexation/trial-expired")) && (
                <FormattedMessage id={`marketing/pricing/description`} />
              )}

              {why === "indexation/index-all" && (
                <FormattedMessage id="marketing/pricing/description/indexation/index-all" />
              )}

              {why === "indexation/auto-index" && (
                <FormattedMessage id="marketing/pricing/description/indexation/auto-index" />
              )}

              {why === "indexation/quota" && (
                <FormattedMessage id="marketing/pricing/description/indexation/quota" />
              )}
              {why === "indexation/trial-expired" && (
                <FormattedMessage id="marketing/pricing/description/trial-expired" />
              )}

              {why === "multi-google-search" && (
                <FormattedMessage id="marketing/pricing/description/multi-google-search" />
              )}
            </p>

            <svg
              viewBox="0 0 1208 1024"
              className="lg:-top-50 absolute -top-10 left-1/2 -z-10 h-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:-top-12 md:-top-20 xl:top-0"
            >
              <ellipse
                cx={604}
                cy={512}
                fill="url(#6d1bd035-0dd1-437e-93fa-59d316231eb0)"
                rx={604}
                ry={512}
              />
              <defs>
                <radialGradient id="6d1bd035-0dd1-437e-93fa-59d316231eb0">
                  <stop stopColor="#f472b6" />
                  <stop offset={1} stopColor="#fbcfe8" />
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div>

        <div className="mt-12 flow-root bg-white pb-8">
          <div className="-mt-80">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="w-full grid-cols-3 gap-6 md:grid">
                <div></div>
                <PricingSwitchBillingInterval
                  enabled={interval === "yearly"}
                  setEnabled={() => {
                    if (interval === "yearly") setInterval("monthly")
                    if (interval === "monthly") setInterval("yearly")
                  }}
                />
                <div></div>
              </div>

              <div
                className={clsx(
                  "mx-auto mt-4 grid max-w-md grid-cols-1 gap-4 lg:max-w-7xl",
                  tiers.length >= 4 ? "lg:grid-cols-4" : "lg:grid-cols-3"
                )}
              >
                {tiers.map((tier, tierIndex) => {
                  const tierPlan =
                    tier.plan === PaymentPlansEntity["indexation/free"]
                      ? { level: 0 }
                      : findPlanByNameAndInterval({
                          interval: "monthly",
                          planName: tier.plan,
                        })

                  const actualPlan = props.actualPlan
                    ? findPlanByNameAndInterval({
                        interval: "monthly",
                        planName: props.actualPlan.plan,
                      })
                    : { level: -1 }

                  const priceAfterReduction = roundDecimal(
                    props.products[tier.plan][interval] *
                      (1 - (tier.reduction || 0))
                  )

                  return (
                    <div
                      key={tierIndex}
                      className={classNames(
                        "relative flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-slate-200 sm:p-6",
                        tier.highlight &&
                          !props.actualPlan?.plan &&
                          "ring-4 !ring-pink-300",
                        tier.plan === props.actualPlan?.plan &&
                          "ring-4 !ring-pink-300"
                      )}
                    >
                      {tier.highlight && !props.actualPlan?.plan && (
                        <div className="absolute right-4 top-4">
                          <div className="flex items-center justify-center rounded-lg bg-pink-400 px-3 py-1  text-sm text-white">
                            <FormattedMessage id="marketing/pricing/popular" />
                          </div>
                        </div>
                      )}

                      {props.actualPlan?.plan === tier.plan && (
                        <div className="absolute right-4 top-4">
                          <div className="flex items-center justify-center rounded-lg bg-pink-400 px-3 py-1  text-sm text-white">
                            <>Your plan</>
                          </div>
                        </div>
                      )}
                      <div>
                        <h2
                          id={tier.id}
                          className=" text-base font-semibold leading-7 text-pink-400"
                        >
                          <FormattedMessage id={tier.name} />
                        </h2>
                        <div className="flex items-baseline ">
                          <span className="mr-2  text-3xl font-bold tracking-tight text-slate-900">
                            {priceAfterReduction}
                            {getSymbolFromCurrency(props.products.currency)}
                          </span>

                          {tier.reduction !== 0 && (
                            <span
                              className={classNames(
                                " text-3xl font-bold tracking-tight",
                                "text-base font-semibold text-slate-400 line-through"
                              )}
                            >
                              {props.products[tier.plan][interval]}

                              {getSymbolFromCurrency(props.products.currency)}
                            </span>
                          )}

                          <span className=" text-base font-medium leading-7 text-slate-400">
                            <FormattedMessage
                              id={`marketing/pricing/premium/${interval}`}
                            />
                          </span>
                        </div>

                        <ul
                          role="list"
                          className="mt-6 space-y-4 text-sm leading-6 "
                        >
                          {tier.features.map((feature, featureIndex) => (
                            <div key={featureIndex}>
                              <li
                                key={featureIndex}
                                className={"flex gap-x-3 "}
                              >
                                <CheckIcon
                                  className="h-6 w-5 flex-none text-pink-400"
                                  aria-hidden="true"
                                />

                                <FormattedMessage id={feature} />
                              </li>
                            </div>
                          ))}

                          {tier.featuresComingSoon.map(
                            (feature, featureIndex) => (
                              <div key={featureIndex}>
                                <li
                                  key={featureIndex}
                                  className={"flex gap-x-3 "}
                                >
                                  <CheckIcon
                                    className="h-6 w-5 flex-none text-pink-400"
                                    aria-hidden="true"
                                  />

                                  <FormattedMessage id={feature} />
                                </li>
                              </div>
                            )
                          )}
                        </ul>
                      </div>
                      <div className="mt-8">
                        <ButtonSecondary
                          onClick={() =>
                            props.onSubscribe(
                              tier.plan as PaymentPlansEntity,
                              interval
                            )
                          }
                          disabled={tierPlan.level <= actualPlan.level}
                          fullWidth
                        >
                          {tier.plan ===
                            PaymentPlansEntity["indexation/free"] && (
                            <FormattedMessage id="marketing/pricing/free/cta" />
                          )}
                          {tier.plan !==
                            PaymentPlansEntity["indexation/free"] && (
                            <FormattedMessage
                              id={"marketing/pricing/premium/cta"}
                            />
                          )}
                        </ButtonSecondary>
                      </div>
                    </div>
                  )
                })}

                <div className="flex flex-col items-start gap-x-8 gap-y-6 rounded-3xl bg-white p-8 ring-1 ring-slate-200 sm:gap-y-10 sm:p-10 lg:col-span-4 lg:flex-row lg:items-center">
                  <div className="lg:min-w-0 lg:flex-1">
                    <h2 className=" text-lg font-semibold leading-8 tracking-tight text-pink-400">
                      <FormattedMessage
                        id={"marketing/pricing/on-demand/label"}
                      />
                    </h2>
                    <p className="mt-1 text-base leading-7 text-slate-900">
                      <FormattedMessage
                        id={"marketing/pricing/on-demand/description"}
                      />
                    </p>
                  </div>
                  <ButtonSecondary onClick={props.onSupport}>
                    <FormattedMessage id={"marketing/pricing/on-demand/cta"} />
                    <span aria-hidden="true" className="ml-1">
                      &rarr;
                    </span>
                  </ButtonSecondary>
                </div>
              </div>

              <PaymentsMethods />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Connected: React.FC<ContainerProps> = (props) => <Wrapper {...props} />
export const Pricing = connector(Connected)

const ConnectedLanding: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)
export const PricingLanding = connector(ConnectedLanding)
